@media only screen and (max-width: 999px) {
    .alt-header .contact-bar-wrap {
        padding-top:0
    }

    .alt-header .contact-bar-wrap .plus-pattern {
        display: none
    }
}

@media only screen and (max-width: 599px) {
    .alt-header  .contact-bar-wrap {
        padding-bottom:100px
    }
}

.alt-header .contact-bar-wrap {
    position: relative;
    padding-top: 70px;
    padding-bottom: 175px
}

.alt-header .contact-bar-wrap .plus-pattern {
    display: block;
    width: 707px;
    height: 654px;
    position: absolute;
    bottom: -95px;
    left: -210px;
    //background: url(/svg/plus-pattern-gray.svg) 50% no-repeat;
    background-size: contain
}

.alt-header .cu-section__contact-bar {
    width: 1470px;
    position: relative;
    margin: 0 auto;
    -webkit-box-shadow: 6px 6px 24px rgba(0,0,0,.16);
    box-shadow: 6px 6px 24px rgba(0,0,0,.16);
    background: #fff
}

.alt-header .cu-section__contact-bar.has-image .left:after {
    content: "";
    display: table;
    clear: both
}

.alt-header .cu-section__contact-bar.has-image .left .image {
    width: 200px;
    height: 200px;
    float: left;
    margin-right: 50px;
    margin-left: 50px;
    border-radius: 50%;
    overflow: hidden
}

.alt-header .cu-section__contact-bar.has-image .left .inner-content-wrap {
    width: calc(100% - 300px);
    float: left;
    padding-right: 15px
}

@media only screen and (max-width: 1259px) {
    .alt-header .cu-section__contact-bar.has-image {
        padding-left:0
    }

    .alt-header .cu-section__contact-bar.has-image .left .image {
        float: none;
        margin-bottom: 35px
    }

    .alt-header .cu-section__contact-bar.has-image .left .inner-content-wrap {
        width: calc(100% - 50px);
        float: none;
        margin-left: 50px;
        padding-right: 0
    }
}

@media only screen and (max-width: 999px) {
    .alt-header .cu-section__contact-bar.has-image .left .image {
        margin:0 auto 35px
    }

    .alt-header .cu-section__contact-bar.has-image .left .inner-content-wrap {
        margin: 0 auto
    }
}

.alt-header .cu-section__contact-bar .left {
    width: calc(100% - 200px);
    padding: 35px 0
}

.alt-header .cu-section__contact-bar .left .inner-content-wrap {
    position: relative;
    width: calc(100% - 140px);
    margin: 0 auto;
    padding-left: 80px
}

.alt-header .cu-section__contact-bar .left .inner-content-wrap:before {
    content: "";
    display: block;
    width: 60px;
    height: 2px;
    position: absolute;
    top: 20px;
    left: 0;
    background: #c93
}

.alt-header .cu-section__contact-bar .left .inner-content-wrap .copy {
    width: 670px;
    margin-top: 25px
}

.alt-header .cu-section__contact-bar .left .inner-content-wrap .contact-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    -webkit-column-gap: 50px;
    -moz-column-gap: 50px;
    column-gap: 50px;
    margin-top: 25px;
    font-size: 0
}

.alt-header .cu-section__contact-bar .left .inner-content-wrap .contact-info h4 {
    font-family: acumin-pro-semi-condensed,Helvetica,Arial,sans-serif;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #7b7f7f
}

.alt-header .cu-section__contact-bar .left .inner-content-wrap .contact-info a {
    color: inherit;
    text-decoration: none
}

.alt-header .cu-section__contact-bar .left .inner-content-wrap .contact-info>div {
    font-size: 1rem;
    line-height: 1.55em
}

.alt-header .cu-section__contact-bar .left .inner-content-wrap .contact-info .contact .info dt,.cu-section__contact-bar .left .inner-content-wrap .contact-info .location .info dt {
    display: inline;
    font-family: acumin-pro-semi-condensed,Helvetica,Arial,sans-serif;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #7b7f7f
}

.alt-header .cu-section__contact-bar .left .inner-content-wrap .contact-info .contact .info dd,.cu-section__contact-bar .left .inner-content-wrap .contact-info .location .info dd {
    display: inline;
    font-family: acumin-pro-semi-condensed,Helvetica,Arial,sans-serif
}

.alt-header .cu-section__contact-bar .left .inner-content-wrap .contact-info .address span,.cu-section__contact-bar .left .inner-content-wrap .contact-info .hours span {
    display: block;
    max-width: 220px;
    font-family: acumin-pro-semi-condensed,Helvetica,Arial,sans-serif
}

.alt-header .cu-section__contact-bar .right {
    display: grid;
    grid-template-rows: 1fr 1fr;
    width: 200px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0
}

.alt-header .cu-section__contact-bar .right .button .btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

@media only screen and (max-width: 1599px) {
    .alt-header .cu-section__contact-bar {
        width:90%
    }
}

@media only screen and (max-width: 1399px) {
    .alt-header .cu-section__contact-bar .left .inner-content-wrap {
        position:relative;
        width: calc(100% - 70px);
        margin: 0 0 0 auto;
        padding-left: 50px
    }

    .alt-header .cu-section__contact-bar .left .inner-content-wrap:before {
        content: "";
        display: block;
        width: 30px;
        height: 2px;
        position: absolute;
        top: 20px;
        left: 0;
        background: #c93
    }

    .alt-header .cu-section__contact-bar .left .inner-content-wrap .copy {
        width: 100%;
        padding-right: 25px
    }
}

@media only screen and (max-width: 1159px) {
    .alt-header .cu-section__contact-bar .left {
        width:100%
    }

    .alt-header .cu-section__contact-bar .left .inner-content-wrap {
        width: calc(100% - 35px)
    }

    .alt-header .cu-section__contact-bar .right {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        width: 100%;
        height: 100px;
        position: static
    }
}

@media only screen and (max-width: 999px) {
    .alt-header .cu-section__contact-bar .left .headline {
        text-align:center
    }

    .alt-header .cu-section__contact-bar .left .inner-content-wrap {
        width: 100%;
        padding-left: 0;
        margin: 0 auto
    }

    .alt-header .cu-section__contact-bar .left .inner-content-wrap:before {
        position: static;
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto
    }

    .alt-header .cu-section__contact-bar .left .inner-content-wrap .contact-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        -webkit-column-gap: 50px;
        -moz-column-gap: 50px;
        column-gap: 50px;
        width: 80%;
        margin: 25px auto 0
    }

    .alt-header .cu-section__contact-bar .left .inner-content-wrap .contact-info>div {
        margin-bottom: 35px
    }

    .alt-header .cu-section__contact-bar .left .inner-content-wrap .contact-info>div:last-child {
        margin-bottom: 0
    }

    .alt-header .cu-section__contact-bar.layout-description .left {
        width: 90%;
        margin: 0 auto
    }

    .alt-header .cu-section__contact-bar.layout-description .left .inner-content-wrap .copy {
        padding-right: 0
    }
}

@media only screen and (max-width: 749px) {
    .alt-header .cu-section__contact-bar {
        margin:0 auto
    }

    .alt-header .cu-section__contact-bar .left .inner-content-wrap .contact-info {
        grid-template-columns: 1fr
    }
}
