.page-template-page-components {

	.cu-section__simple-content.singletextrow {
		margin: 10px 0px 40px 0px;
	}

	.cu-section__simple-content.singletextrow .clearfloat{
		clear: both;
	}

	.cu-section__simple-content.singletextrow .inner-wrap:before {
		display:none;
	}

	.cu-section__simple-content.singletextrow .inner-wrap {
		padding:0px;
		width: 100%; max-width:1400px;
		.bottomlinewrapper {border-bottom: solid 2px #cc9933; text-align: center; margin-left:auto; margin-right:auto; display: inline-block; padding-bottom: 0px; margin-bottom: 15px; text-align: center;}
	}
	.cu-section__simple-content.singletextrow.inverted {
		background-color:#162b48;
		.copy, h2 {color:#ffffff;
		}

		
		
	}

	@media only screen and (max-width: 1549px) {
		.cu-section__simple-content.singletextrow .inner-wrap  {
			width:90%
		}
	}

	@media only screen and (min-width: 1259px) {
		.cu-section__simple-content.singletextrow .inner-wrap  {
			padding: 0px 80px;
		}
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar {padding: 0px 0px 0px 80px;}
	}


	@media only screen and (min-width: 1000px) {
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar .sidebarcontent {margin-left:50px; margin-bottom:50px;}
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar .copy {display:block; flex:none;}
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar .sidebarcontent {width:400px; float:right;}
	}

	@media only screen and (max-width: 999px) {

		.cu-section__simple-content.singletextrow .inner-wrap {width:80%;}
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar .copy {flex-direction: column; display:flex;}
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar .sidebarcontent {margin: 20px 0 0 0;max-width: 100%; order: 2; float:none;}
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar .textsection {order: 1;}
	}
	
	@media only screen and (max-width: 599px) {

		.cu-section__simple-content.singletextrow .inner-wrap {width:calc(100% - 40px);}
	}

}