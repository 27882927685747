.cu-section__form-hero {
	position: relative;
	z-index: 2;
	padding-top: 107px;

	.home & {
		&:before {
			height: 740px;
		}

		.inner-wrap {
			height: 700px;
		}

		.inner-content-wrap h1,
		.inner-content-wrap h2 {
			@include section-line-before(60px, 40px);
			width: 600px;

			@include max-down(1225px) {
				@include section-line-before(60px, 35px);
				width: 450px;
			}

			@include max-down(1000px) {
				@include section-line-before-break;
				width: 100%;
			}
		}
	}

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 565px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		background: $blue;
		background: linear-gradient(45deg, $blue 5%, $dblue 65%);
	}

	.inner-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc(100% - 80px);
		height: 530px;
		position: relative;
		z-index: 2;
		margin: 0 auto;

		.image,
		.tablet-image,
		.mobile-image {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}

		.tablet-image,
		.mobile-image {
			display: none;
		}

		.video {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 3;
			background: rgba($black, 0.4);
		}

		.mobile-back-wrap {
			display: none;
			position: absolute;
			top: 25px;
			left: 20px;
			z-index: 4;
		}
	}

	.inner-content-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: calc(100% - 200px);
		position: relative;
		z-index: 4;
		padding-right: 80px;

		h1,
		h2 {
			@include section-line-before(60px, 23px);
			padding-left: 80px;
		}
	}

	.mobile-form {
		width: 450px;
		position: absolute;
		top: 50%;
		right: 220px;
		transform: translateY(-30%);
		z-index: 5;
		background: $dblue;

		h2,
		h3 {
			padding: 12px 5%;
			background: $gold;
			text-align: center;
		}

		.copy {
			padding: 20px 25px;
		}
	}

	@include max-down(1500px) {
		.inner-content-wrap {
			padding-right: 0;
		}

		.mobile-form {
			right: 140px;
		}
	}

	@include max-down(1375px) {
		.inner-content-wrap {
			width: calc(100% - 80px);
		}

		.mobile-form {
			right: 80px;
		}
	}

	@include max-down(1225px) {
		.inner-content-wrap {
			h1,
			h2 {
				width: 450px;
			}
		}

		.mobile-form {
			width: calc(100% - 660px);
		}
	}

	@include max-down(1160px) {
		.inner-wrap {
			.image {
				display: none;
			}

			.tablet-image {
				display: block;
			}
		}
	}

	@include max-down(1000px) {
		.home & {
			&:before {
				height: 540px;
			}

			.inner-wrap {
				height: 500px;
			}
		}

		.inner-wrap .mobile-back-wrap {
			display: block;
		}

		.inner-content-wrap {
			h1,
			h2 {
				@include section-line-before-break;
				width: 100%;
				padding-left: 0;
			}
		}

		.mobile-form {
			width: calc(100% - 80px);
			position: static;
			transform: none;
			margin: 15px auto 0;
		}
	}

	@include max-down(750px) {
		&:before {
			height: 425px !important;
		}

		.inner-wrap {
			width: calc(100% - 40px);
			height: 360px !important;
		}

		.mobile-form {
			width: calc(100% - 40px);
		}
	}

	@include max-down(600px) {
		.inner-wrap {
			.tablet-image {
				display: none;
			}

			.mobile-image {
				display: block;
			}
		}
	}
}