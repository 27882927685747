.page-template-page-components {
	.cu-section__simple-content.htmlblock.tbx-animated .inner-wrap.clearline::before  {
			display: none;
	}
	.cu-section__simple-content.htmlblock.tbx-animated .inner-wrap {
			padding-top:inherit;
	}
	.cu-section__simple-content {
		background-color:#ffffff;
	}

}
